<template>
  <div class="Lookinvitepar">
    <div class="top-titles">
      <div class="title">
          <span>招聘信息</span>/招聘详情
      </div>
     
     <div class="top-box-images">
         <img class="top-box-images-image" v-for="(item,i) in listpar.profile_photos" :key="i" :src="item" alt="">
     </div>
    </div>

    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
          <div class="particulars-list" ><span class="pl-tltle">标题:</span><span class="pl-content">{{listpar.headline}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">工种:</span><span class="pl-content">{{listpar.kind}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">到岗时间:</span><span class="pl-content">{{listpar.arrivalTime}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">工作性质:</span><span class="pl-content">{{listpar.work}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">工价:</span><span class="pl-content">{{listpar.salary}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">招聘人数:</span><span class="pl-content">{{listpar.recruitingNumbers}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">工作时间:</span><span class="pl-content">{{listpar.workingHours}}</span></div>
        
          <div class="particulars-list" ><span class="pl-tltle">支付招聘总费用:</span><span class="pl-content">{{listpar.promise_payplat_total}}</span></div>
         
          <div class="particulars-list" ><span class="pl-tltle">招聘要求:</span><span class="pl-content pl-content-i"><i v-for="(item,i) in listpar.askFor" :key="i">{{item.yaoqiu}}</i></span></div>
          <div class="particulars-list" ><span class="pl-tltle">工厂名称:</span><span class="pl-content">{{listpar.companyName}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">联系人:</span><span class="pl-content">{{listpar.linkman}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">联系电话:</span><span class="pl-content">{{listpar.phone}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">工厂地址:</span><span class="pl-content">{{listpar.companyAddress}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">备注:</span><span class="pl-content">{{listpar.remark}}</span></div>
           <div class="particulars-list" v-if="logintype ==1?true:false"><span class="pl-tltle">超级会员单位佣金:</span><span class="pl-content">{{listpar.share_commission_price0}}</span></div>
          <div class="particulars-list"  v-if="logintype ==1?true:false"><span class="pl-tltle">超级会员外挂单位佣金:</span><span class="pl-content">{{listpar.share_commission_price1}}</span></div>
          <div class="particulars-list"  v-if="logintype ==1?true:false"><span class="pl-tltle">经理单位佣金:</span><span class="pl-content">{{listpar.share_commission_price2}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">单位费用:</span><span class="pl-content">{{listpar.price_toplat}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">月综合工资:</span><span class="pl-content">{{listpar.month_multiple_price}}</span></div>
      </div>

        <div class="particulars-list" style="width:800px;margin:0 auto;padding-bottom:50px; text-align: center;"><span class="pl-tltle">职位描述:</span><span class="pl-content" v-html="listpar.job"></span></div>



    <div class="bohui" v-if="listpar.refuseReason">
      <span class="bohui-tltle">驳回理由:</span>
      <span class="bohui-content">{{listpar.refuseReason}}</span>
    </div>

    <div class="bohui" v-if="listpar.dropdownReason">
      <span class="bohui-tltle">下架理由:</span>
      <span class="bohui-content">{{listpar.dropdownReason}}</span>
    </div>
     
     
      <div class="particulars-map" v-if="flag">
        工作地址：<Maps :weizhi="listpar" />
      </div>


      <div class="unamortizedpar-affirm">
        <div>
           <el-button v-if="listpar.statusCode == 1?true:false || (listpar.statusCode == 2 && listpar.status.indexOf('自动') == -1)?true:false"
               type="info" class="biy-icon" @click="dialogFormVisible = true">下架</el-button>
          </div>
      </div>
    </div>

      <el-dialog title="请输入下架理由" :visible.sync="dialogFormVisible" width="450px">
      <el-form :model="listpar" label-width="80px">
            <el-form-item label="下架理由" >
                  <el-input v-model="listpar.liyou"></el-input>
          </el-form-item>
      </el-form>
          <div div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="xiajia()">确 定</el-button>
            </div>
        </el-dialog>


  </div>
</template>
<script>
import Maps from "@/components/maps/Maps.vue"
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  name:'maps',
  components: { Maps},
  data() {
    return {
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      dialogFormVisible:false,

      listpar: {},
      flag:false,
      logintype:this.$common.baseGetSeparate(),
    };
  },

  created(){
   this.getSong()
  },
  mounted() {
  },

  methods: {

    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'RecruitEntity',
        handleMode:'fetch',
        bizId:this.$route.params.id,
        })
        console.log(res);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
          this.listpar.zoom = 15;
           this.flag=true
      },

  // 下架理由
    async  xiajia(){
    	let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家

        bizCatalog:'RecruitEntity',
        handleMode:'dropdown',
        bizId:this.listpar.id,
        reason:this.listpar.liyou,
       })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
         this.dialogFormVisible = false
         this.$alert('下架成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/lookinvite`});
          }
        });
    }

  },
  computed: {},
};
</script>


<style  scoped>
/* 待审核商家按钮 */
.unamortizedpar-affirm{
    height: 70px;
    line-height: 70px;
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    padding: 20px 0 200px 0;
}
.unamortizedpar-affirm .biy-icon{
    width: 100px;
    border-radius: 20px;
}

  .bohui{
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 50px;
  }
  .bohui-title{
        font-size: 15px;
    color: #777;
  }
  .bohui-content{
        font-size: 15px;
    padding-left: 10px;
    color: rgb(207, 34, 34);
  }
.top-box-images{
    height: 140px;
    padding-top: 20px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-around;
}
.top-box-images .top-box-images-image{
    height: 120px;
    width: auto;
    margin: 0 auto;
}   


@import "../../assets/css/parstyle.css";

</style>